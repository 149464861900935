var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "about" },
    [
      _c("h1", [_vm._v("This is an about page")]),
      _c(
        "el-breadcrumb",
        { attrs: { "separator-class": "el-icon-arrow-right" } },
        [
          _c("el-breadcrumb-item", { attrs: { to: { name: "home" } } }, [
            _vm._v("Home"),
          ]),
          _vm._l(_vm.breadList, function (item, index) {
            return _c("el-breadcrumb-item", { key: index }, [
              _vm._v(_vm._s(item.meta.title)),
            ])
          }),
        ],
        2
      ),
      _vm._v("\n  aaa: " + _vm._s(_vm.breadList) + "\n  "),
      _c(
        "el-button",
        {
          on: {
            click: function ($event) {
              return _vm.testAA()
            },
          },
        },
        [_vm._v("OK123")]
      ),
      _c("currency-input", {
        attrs: { disabled: "" },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
      _c("el-currency-input", {
        attrs: { disabled: "" },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
      _c("br"),
      _vm._v("\n  " + _vm._s(_vm.testWatch) + "\n  "),
      _c(
        "el-button",
        {
          on: {
            click: function ($event) {
              return _vm.changeWatch()
            },
          },
        },
        [_vm._v("Change1")]
      ),
      _vm._v("\n  " + _vm._s(_vm.test111) + "\n  "),
      _c("br"),
      _vm._v("\n  " + _vm._s(_vm.testWatchObject) + "\n  "),
      _c(
        "el-button",
        {
          on: {
            click: function ($event) {
              return _vm.changeWatchPro1Method()
            },
          },
        },
        [_vm._v("Change2")]
      ),
      _vm._v("\n  " + _vm._s(_vm.testObjectPro1) + " "),
      _c("br"),
      _c(
        "el-button",
        {
          on: {
            click: function ($event) {
              return _vm.testAAFormat()
            },
          },
        },
        [_vm._v("Format")]
      ),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }